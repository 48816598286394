import { Axios } from 'axios';
import LanguageProvider from '../language.provider'

const TopicFaildStatuses = {
    InvalidName: "InvalidName"
}

class TopicsApi {
    constructor(axios, languageProvider) {
        this.axios = axios;
        this.languageProvider = languageProvider;
    }

    async getViewed(titleSample, skip, limit) {
        const sourceLanguageId = await this.languageProvider.getSourceId()

        const params = {
            search: titleSample,
            languageId: sourceLanguageId
        }

        const headers = {
            skip,
            limit
        }

        const response = await this.axios.get('topics/views', { params, headers })
        return response.data
    }

    async getPopular(titleSample, skip, limit) {
        const sourceLanguageId = await this.languageProvider.getSourceId()

        const params = {
            search: titleSample,
            languageId: sourceLanguageId
        }

        const headers = {
            skip,
            limit
        }

        const response = await this.axios.get('topics/popular', { params, headers })
        return response.data
    }

    async view(topicId) {
        try {
            await this.axios.post(`topics/${topicId}/views`, {})
        }
        catch (error) {
            if (error.response && error.response.status === 409) {
                return
            }
        }
    }

    async generate(name) {
        try {
            const response = await this.axios.put("topics", {
                sourceLanguageId: await this.languageProvider.getSourceId(),
                targetLanguageId: await this.languageProvider.getTargetId(),
                name: name
            })

            return response.data
        }
        catch (error) {
            if (error.response && error.response.status === 422) {
                // Handle 422 Unprocessable Entity error
                return TopicFaildStatuses.InvalidName;
            } else {
                // Handle other errors
                throw error;
            }
        }
    }
}

export default TopicsApi;
export { TopicFaildStatuses }