import "./not.active.subscription.css";
import PaymentPageButton from "../payment-page-button/payment.page.button";
const NotActiveSubscription = ({ status, price, buyClick }) => {
  return (
    <div className="not_active_subscription_wrapper">
      <div className="not_active_subscription_container">
        <span className="not_active_subscription_plan">{status}</span>
        <span className="subscription_price">${price}/m</span>
        <div className="subscriptions_payment_button">
          <PaymentPageButton
            title="Buy"
            buttonColor="#FFFFFF"
            titleColor="#A180C8"
            hoverColor="hoverColorWhite"
            enabled="disabled"
            onClick={() => buyClick()}
          />
        </div>
        <ul className="subscription_info">
          <li>Unlimited exercises</li>
          <li>Unlimited topic generation</li>
          <li>Popular topics</li>
        </ul>
      </div>
    </div>
  );
};

export default NotActiveSubscription;
