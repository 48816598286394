import logo from "./logo.svg";
import "./App.css";
import factorySingleton from "./services/factory";
import Header from "./components/header/Header";
import SearchScreen from "./screens/home/home";
import TopicOwerviewScreen from "./screens/TopicOwerviewScreen/TopicOwerviewScreen";
import DataView from "react";
import { BrowserRouter as Router, Route, Routes, Link, useLocation, BrowserRouter } from "react-router-dom";
import Dictionary from './screens/dictionaryScreen/dictionary'
import Topics from "./screens/topics/topics.screen";
import TopicMenu from "./components/topic-menu/topic.menu";
import Exercise from "./screens/exerciseScreen/exercise"
import GenerateTopicScreen from "./screens/generateTopicScreen/generate.topic"
import LoginScreen from "./screens/loginScreen/login"
import RegistrationScreen from "./screens/registrationScreen/registration"
import ResponseInterceptor from "./components/common/response.interceptor";
import SubscriptionScreen from "./screens/subscription/subscription"
import SubscripedPopup from "./components/subscriptions/subscribed.popup";
import PaymentPagePopUp from './components/payment-page-popUp/payment.page.popUp'
import SubscriptionCheker from "./components/common/subscription.checker.job";
import Footer from "./components/footer/footer";
import { useLayoutEffect } from "react";
import { UmamiTracker } from './helpers/umamu';

function App() {
  const location = useLocation();
  console.log(location.pathname)
  factorySingleton.setup();

  useLayoutEffect(() => {
    document.title = "Polyglotio";
  }, []);

  return (
    <>
      <UmamiTracker />
      <ResponseInterceptor />
      {/* <SubscriptionCheker /> */}
      {/* <PaymentPagePopUp title={'Congratulations, you have subscribed'} icon={'./images/birthday_party_confetti.svg'} buttonText={'Continue'} /> */}
      {/* { <PaymentPagePopUp title={"Unfortunately, the payment didn't go through."} icon={'./images/wrong_payment.png'} buttonText={'Continue'} support={'Contact support: support@polyglotio.com'} /> } */}
      {isShowHeader(location.pathname) && <Header />}
      <Routes>
        <Route path='' element={<Topics />} />
        {/* <Route path="/dictionary" element={<Dictionary />} /> */}
        <Route path="/exercises" element={<Exercise />} />
        <Route path="/generation" element={<GenerateTopicScreen />} />
        <Route path="/sign-in" element={<LoginScreen />} />
        <Route path="/sign-up" element={<RegistrationScreen />} />
        <Route path="/subscriptions" element={<SubscriptionScreen />} />
        <Route path="/subscriptions/payments/success/:sessionId" element={<SubscriptionScreen />} />
      </Routes>
      {isShowFooter(location.pathname) && <Footer />}
    </>
  );
}

function isShowHeader(path) {
  if (path === "/") {
    return true;
  }

  if (path == "/generation") {
    return true
  }

  return false;
}

function isShowFooter(path) {
  if (path === "/") {
    return true;
  }



  return false;
}

export default App;
