import "./generate.description.css"

const GenerateDescription = ({ descriptionItems }) => {
  return (
    <div className="generate_description_wrapper">
      <div className="generate_description_container">
      <span class="generate_description_examples">Examples</span>
        <ul className="generate_description_list" >
          {descriptionItems.map((x, i) =>
            <li key={i}>{x}</li>
          )
          }
        </ul>
      </div>
    </div>
  )
}
export default GenerateDescription;