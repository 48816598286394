import "./generate.topic.css";
import Search from "../../components/search/Search";
import GenerateTopicError from "../../components/generateTopicError/generate.topic.error";
import GenerateTopicBox from "../../components/generateTopicBox/generate.topic.box";
import GenerateTopicLink from "../../components/generateTopicLink/generate.topic.link";
import { useEffect, useRef, useState } from "react";
import factorySingleton from "../../services/factory";
import Loader from "../../components/loader/loader.component";
import TopicMenu from "../../components/topic-menu/topic.menu";
import GenerateDescription from "../../components/generation-descroption/generate.description";

const GenerateTopicScreen = () => {
  const topicsApi = factorySingleton.topicsApi;

  const [loading, setLoading] = useState(false);
  const [input, setInput] = useState(null)
  const [isDisabled, setIsDisabled] = useState(true)
  const [generatedTopic, setGeneratedTopic] = useState(null)
  const [isFailed, setIsFailed] = useState(null);
  const [menuPosition, setMenuPosition] = useState(null);
  const [showDescription, setShowDescription] = useState(true);
  const ref = useRef([
    "Dialogue at a restaurant",
    "Vocabulary about weather",
    "Asking for directions in a new city",
    "Expressions for giving advice",
    "Shopping conversation in a clothing store",
    "Business Spanish vocabulary for meetings",
    "Adjectives to describe personality"
  ])

  useEffect(() => {
    if (input === null) {
      setIsDisabled(true);
    }
    else if (!/[a-zA-Z]/.test(input)) {
      setIsDisabled(true)
    }
    else {
      setIsDisabled(false);
    }
  }, [input])

  const handleGenerate = async () => {
    setGeneratedTopic(null);
    setLoading(true);
    setShowDescription(false);

    const response = await topicsApi.generate(input.trim());
    if (typeof response === 'object') {
      setIsFailed(false)
      setGeneratedTopic(response)
    }
    else {
      setIsFailed(true);
    }
    setLoading(false)
  }

  const changePosition = (e) => {
    setMenuPosition(null);
    const scrollTop = window.scrollY;
    const scrollLeft = window.scrollX;

    setMenuPosition({ top: e.clientY + scrollTop, left: e.clientX + scrollLeft });
  }

  const handleGeneratedTopicClick = (event) => {
    changePosition(event);
  }

  return (
    <div className="generate_topic_container">
      {loading && <Loader />}
      {menuPosition != null && <TopicMenu position={menuPosition} topicId={generatedTopic?.id} />}
      <div className="generate_search_bar">
        <div style={{ width: "30vw", maxWidth: "576px" }}>
          <Search value={input} onChange={v => setInput(v)} />
        </div>
        <button className="generate_topic_button" disabled={isDisabled} onClick={handleGenerate}>
          <span className="generate">Generate</span> <img className="generate_icon" src="./images/generate-icon.svg" alt="" />
        </button>
      </div>
      {isFailed && <GenerateTopicError topic_name={input} />}
      {generatedTopic !== null && <GenerateTopicBox onClick={handleGeneratedTopicClick} img={factorySingleton.apiBaseUrl + `/topics/${generatedTopic?.id}/image`} box_title={generatedTopic?.name} />}
      {/* <GenerateTopicLink /> */}
      {showDescription && <GenerateDescription descriptionItems={ref.current} />}
    </div>
  );
};

export default GenerateTopicScreen;
