import "./registration.css";
import CredentialsInput from "../../components/credentials-input/credentials.input";
import PaymentPageButton from "../../components/payment-page-button/payment.page.button";
import { useState, useRef, useEffect } from "react";
import factorySingleton from "../../services/factory";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from 'react-google-recaptcha';
import WrongCredentials from "../../components/wrong-credentials/wrong.credentials";
import { trackEvent } from "../../helpers/umamu";

const RegistrationScreen = () => {
  const navigate = useNavigate();

  const recaptchaRef = useRef(null);

  const identityApi = factorySingleton.identityApi;
  const tokenService = factorySingleton.tokensService;

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [repeatPassword, setRepeatPassword] = useState(null);

  // errors
  const [nameError, setNameError] = useState(null);
  const [emailError, setEmailError] = useState(null);
  const [passwordError, setPasswordError] = useState(null);
  const [repeatPasswordError, setRepeatPasswordError] = useState(null);

  const nameRegex = /^.{2,10}$/;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  const passwordRegex = /^.{6,20}$/;

  const [registerEnabled, setRegisterEnabled] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null)
  const [alreadyRegistered, setAlreadyRegistered] = useState(null)

  useEffect(() => {
    if (tokenService.getTokens()) {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    trackEvent('registration-name-change')
  }, [name])

  useEffect(() => {
    trackEvent('registration-password-change')
  }, [password])

  useEffect(() => {
    trackEvent('registration-email-change')
  }, [email])

  useEffect(() => {
    if (name?.length > 0) {
      if (!nameRegex.test(name)) {
        setNameError('Input valid name')
      }
      else {
        setNameError(null);
      }
    } else {
      setNameError(null);
    }
  }, [name])

  useEffect(() => {
    if (email?.length > 0) {
      if (!emailRegex.test(email)) {
        setEmailError('Input valid email')
      }
      else {
        setEmailError(null)
      }
    } else {
      setEmailError(null)
    }
  }, [email])

  useEffect(() => {
    if (repeatPassword === null && password?.length > 0 && !passwordRegex.test(password)) {
      setPasswordError('Password minimal length: 6')
    }
    else if (password !== null && repeatPassword !== null) {
      if (password != repeatPassword) {
        setPasswordError("Passwords are not equels");
        setRepeatPasswordError("Passwords are not equels");
      }
      else {
        setPasswordError(null)
        setRepeatPasswordError(null)
      }
    }
    else {
      setPasswordError(null)
      setRepeatPasswordError(null)
    }
  }, [password, repeatPassword])

  useEffect(() => {
    console.log('recaptha: ' + recaptchaToken)
    setRegisterEnabled(
      name !== null
      && email !== null
      && password !== null
      && repeatPassword !== null
      && recaptchaToken !== null
      && nameError === null
      && emailError === null
      && passwordError === null
      && repeatPasswordError === null)
  }, [name, email, password, repeatPassword, nameError, emailError, passwordError, repeatPasswordError, recaptchaToken])

  const registerClick = async () => {
    if (!recaptchaToken) {
      return;
    }

    setAlreadyRegistered(null)

    const result = await identityApi.register({ name, email, password, recaptchaToken });
    if (result.recaptcha) {
      recaptchaRef.current.reset();
    }
    if (!result.success) {
      recaptchaRef.current.reset();
      setAlreadyRegistered(email)
      return;
    }
    setAlreadyRegistered(null)
    tokenService.setToken(result.token);
    navigate("/");
  };

  return (
    <div className="registration_wrapper">
      <div class="login_description">
        Want to unlock hundreds of Spanish lessons?
      </div>
      <div className="registration_container">
        {alreadyRegistered !== null && <WrongCredentials info={'User with this email already exists'} />}
        <div className="input_name">Name</div>
        <CredentialsInput
          form_type="text"
          form_placeholder="Enter your name"
          onChange={setName}
          error={nameError}
        />
        <div className="input_name">Email</div>
        <CredentialsInput
          form_type="email"
          form_placeholder="Enter your email"
          onChange={setEmail}
          error={emailError}
        />
        <div class="input_name">Password</div>
        <CredentialsInput
          form_type="password"
          onChange={setPassword}
          form_placeholder="Enter your password"
          error={passwordError}
        />
        <div class="input_name">Password</div>
        <CredentialsInput
          form_type="password"
          form_placeholder="Repeat your password"
          onChange={setRepeatPassword}
          error={repeatPasswordError}
        />

        <div className="recaptcha">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_ReCAPTCHA_Site_Key}
            onChange={(token) => setRecaptchaToken(token)}
          />
        </div>

        <PaymentPageButton
          title="Sign up"
          buttonColor="#A180C8"
          titleColor="#fff"
          hoverColor="hoverColorViolet"
          onClick={registerClick}
          enabled={registerEnabled}
        // showError
        // errorMessage="error"
        />
      </div>
    </div>
  );
};

export default RegistrationScreen;
