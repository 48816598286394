import "./compose.exercise.css";
import ListenWordButton from "../listenWordButton/listen.word.button";
import ProgressBar from "../progressBar/progress.bar";
import DragAndDropArea from "../drag-and-drop-area/drag.and.drop.area";
import ExerciseWordFlexible from "../exercise-word-flexible/exercise.word.flexible";
import { useState, useLayoutEffect, useEffect, forwardRef, useImperativeHandle } from "react";
import factorySingleton from "../../services/factory";
import Loader from "../loader/loader.component";

const ComposeExercise = forwardRef(({ topicId, onSuccess, onFail }, ref) => {
  const exerciseApi = factorySingleton.exercisesApi;

  const [loading, setLoading] = useState(false);
  const [exercise, setExercise] = useState(null);
  const [draggedSourceId, setDragSourceId] = useState(null);
  const [draggedTargetId, setDragTargetId] = useState(null);
  const [sourceWords, setSourceWords] = useState([]);
  const [targetWords, setTargetWords] = useState([]);

  useLayoutEffect(() => {
    setup()
  }, [])

  useEffect(() => { // When chose all words
    if (sourceWords.length === 0 && targetWords.length > 0)
      check();
  }, [sourceWords])

  useImperativeHandle(ref, () => ({
    setup
  }));

  const check = async () => {
    setLoading(true)

    try {
      const result = await exerciseApi.checkComposeExercise(topicId, exercise.exerciseId, targetWords);
      if (result === true) {
        onSuccess()
      }
      else {
        onFail(result.correctText);
      }
    } finally {
      setLoading(false)
    }
  }

  const setup = async () => {
    setSourceWords([])
    setTargetWords([])
    setLoading(true)
    try {
      const composeExercise = await exerciseApi.getComposeExercise(topicId);

      setExercise(composeExercise)
      setSourceWords(composeExercise.words);
    } finally {
      setLoading(false)
    }
  }

  const handleDragStart = (word) => {
    console.log('drag start')
    setDragSourceId(word);
  };

  const handleTargetDragStart = (index) => {
    setDragTargetId(index)
  }

  const handleDrop = () => {
    if (draggedSourceId !== null) {
      const newTargetAreas = [...targetWords];
      newTargetAreas.push(sourceWords[draggedSourceId]);

      const localSourceWords = [...sourceWords];
      localSourceWords.splice(draggedSourceId, 1)

      setSourceWords(localSourceWords);
      setTargetWords(newTargetAreas);
      setDragSourceId(null);
    }
  };

  const handleTargetDrop = () => {
    if (draggedTargetId !== null) {
      const newSourceWords = [...sourceWords];
      newSourceWords.push(targetWords[draggedTargetId]);

      const newTargetWords = [...targetWords]
      newTargetWords.splice(draggedTargetId, 1)

      setSourceWords(newSourceWords);
      setTargetWords(newTargetWords);
      setDragTargetId(null);
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleSourceWordClick = (w, i) => {
    const newSourceWords = [...sourceWords];
    newSourceWords.splice(i, 1);

    setSourceWords(newSourceWords);

    const newTargetWords = [...targetWords]
    newTargetWords.push(w)

    setTargetWords(newTargetWords)
  }

  const handleTargetWordClick = (w, i) => {
    const newSourceWords = [...sourceWords];
    newSourceWords.push(w);

    setSourceWords(newSourceWords);

    const newTargetWords = [...targetWords]
    newTargetWords.splice(i, 1);
    setTargetWords(newTargetWords)
  }

  return (
    <div>
      {loading && <Loader />}
      <p className="drag_and_drop_exercise_description">
        Write this in English
      </p>
      <div style={{ width: "90%" }}>
        <ListenWordButton text={exercise?.text} topicId={topicId} exerciseId={exercise?.exerciseId} />
      </div>
      <DragAndDropArea onWordClick={handleTargetWordClick} onDrop={handleDrop} words={targetWords} onElDragStart={handleTargetDragStart} />
      <div className="drag_and_drop_exercise_words_container_wrapper" onDrop={handleTargetDrop} onDragOver={handleDragOver}>
        <div className="drag_and_drop_exercise_words_container">
          {sourceWords.map((v, i) => <ExerciseWordFlexible word={v} key={i} onClick={() => handleSourceWordClick(v, i)} onDragStart={() => handleDragStart(i)} />)}
        </div>
      </div>
    </div>
  );
});

export default ComposeExercise;
