import LanguagesApi from "./api/languages.api"

class LanguageProvider{
    constructor(languagesApi: LanguagesApi){
        this.languagesApi = languagesApi;
    }

    async getSourceId(){
        const languages = await this.getAll();
        return languages.find(x=> x.name === 'en').id;
    }

    async getTargetId(){
        const languages = await this.getAll();
        return languages.find(x=> x.name === 'es').id;
    }

    async getAll(){
        const languages = JSON.parse(window.sessionStorage.getItem('languages'))
            if (languages !== null)
            return languages;

        const langResponse = await this.languagesApi.getAll()
        window.sessionStorage.setItem('languages', JSON.stringify(langResponse))
        return langResponse
    }
}


export default LanguageProvider