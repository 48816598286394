import "./trial.component.css";

function TrialInfo({ exercises, maxExercises, generations, maxGenerations }) {
  return (
    <div className="trial_wrapper">
      <div className="subscription_spoiler_container">
        <div className="subscription_spoiler_item">
          <div className="subscription_spoiler_text">
            <span className="subscription_spoiler_span">{exercises}</span>{" "}
            exercises left out of{" "}
            <span className="subscription_spoiler_span">{maxExercises}</span>
          </div>
          <span className="not_active_subscription_free">Free</span>
        </div>
        <div className="subscription_spoiler_item">
          <div className="subscription_spoiler_text">
            <span className="subscription_spoiler_span">{generations}</span>{" "}
            generations left out of{" "}
            <span className="subscription_spoiler_span">{maxGenerations}</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrialInfo;
