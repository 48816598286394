import "./subscription.css";
import Header from "../../components/header/Header";
import ActiveSubscription from "../../components/active-subscription/active.subscription";
import NotActiveSubscription from "../../components/not-active-subscription/not.active.subscription";
import React, { useState, useLayoutEffect, useEffect, useCallback } from "react";
import factorySingleton from "../../services/factory";
import Loader from "../../components/loader/loader.component"
import TrialInfo from "../../components/trial-info/trial.component";
import PaymentPagePopUp from '../../components/payment-page-popUp/payment.page.popUp'
import LoadingPaymentPopup from '../../components/loading-payment-popup/loading.payment.popup'
import FailedPaymentPopup from '../../components/failed-payment-popup/failed.payment.popup'
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const SubscriptionScreen = ({ match }) => {
  const navigate = useNavigate();

  console.log(JSON.stringify(match))
  const tokensService = factorySingleton.tokensService;
  const subscriptionsApi = factorySingleton.subscriptionsApi;
  const identityApi = factorySingleton.identityApi;

  const [loading, setLoading] = useState(false);
  const [subscription, setSubscription] = useState(null)
  const [actions, setActions] = useState(null)

  const [checkingSubscriptionPopup, setCheckingSubscriptionPopup] = useState(false)
  const [successPaymentPopup, setSuccessPaymentPopup] = useState(false)
  const [failedPaymentPopup, setFailedPaymentPopup] = useState(false)

  const { sessionId } = useParams();
  console.log(sessionId)
  useLayoutEffect(() => {
    if (sessionId) {
      setCheckingSubscriptionPopup(true)
      const interval = setInterval(async () => {
        const isPaid = await subscriptionsApi.getPaymentSession(sessionId)
        if (isPaid) {
          setCheckingSubscriptionPopup(false)
          setSuccessPaymentPopup(true)
          clearInterval(interval)
          clearTimeout(timeout)
        }
      }, 1000)

      const timeout = setTimeout(() => {
        clearInterval(interval)
        setCheckingSubscriptionPopup(false)
        setFailedPaymentPopup(true)
      }, 30000)

      return () => {
        clearInterval(interval)
        clearTimeout(timeout)
      }
    }
  }, [])

  useEffect(() => {
    if (!successPaymentPopup){
      navigate('/subscriptions')
    }
  }, successPaymentPopup);

  useEffect(() => {
    if (!failedPaymentPopup){
      navigate('/subscriptions')
    }
  }, failedPaymentPopup);

  useEffect(() => {
    let indetvalId = undefined
    const fecth = async () => {
      setLoading(true)
      const userInfo = await tokensService.getUserInfo();

      const subscription = await subscriptionsApi.getSubscription(userInfo.id);
      subscription.till = new Date(subscription.till).toISOString().split('T')[0];
      setSubscription(subscription);

      if (subscription?.type === 'trial') {
        const actionResponse = await identityApi.getTrialAllonece();
        setActions(actionResponse)
      }

      setLoading(false)

      indetvalId = setInterval(async () => {
        const subscription = await subscriptionsApi.getSubscription(userInfo.id);
        subscription.till = new Date(subscription.till).toISOString().split('T')[0];
        setSubscription(subscription)
        if (subscription.type === 'trial') {
          const actionResponse = await identityApi.getTrialAllonece();
          setActions(actionResponse)
        }
        else {
          setActions(null)
        }
      }, 3000)
    }
    fecth()
    return () => clearInterval(indetvalId)
  }, [])

  async function onBuy() {
    const userInfo = await tokensService.getUserInfo()
    const url = await subscriptionsApi.getStripeLink(userInfo.id);
    window.open(url, '_blank')
  }

  const cancelClick = useCallback(async () => {
    await subscriptionsApi.cancellSubscription();
    setSubscription(subscription => ({ ...subscription, cancelled: true }))
  });

  return (
    <div>
      {loading && <Loader />}
      {successPaymentPopup && <PaymentPagePopUp
        title={'Congratulations, you have subscribed'}
        icon={'./images/birthday_party_confetti.svg'}
        buttonText={'Continue'}
        onClick={() => setSuccessPaymentPopup(false)}
        onCrossClick={() => setSuccessPaymentPopup(false)} />}
      {checkingSubscriptionPopup && <LoadingPaymentPopup />}
      {failedPaymentPopup && <FailedPaymentPopup onClick={() => setFailedPaymentPopup(false)} onCrossClick={() => setFailedPaymentPopup(false)} />}
      <Header />
      <span className="subscription_screen">Subscription</span>
      {subscription?.type === 'trial' && <TrialInfo exercises={actions?.allowedExercises} generations={actions?.allowedTopics} maxExercises={actions?.maxExercises} maxGenerations={actions?.maxTopics} />}
      {subscription?.type === 'trial' && <NotActiveSubscription status="Pro" price="2,99" date={subscription?.till} buyClick={onBuy} />}
      {subscription?.type === 'plus' && <ActiveSubscription status="Pro" price="2,99" date={subscription?.till} cancelClick={cancelClick} cancelled={subscription?.cancelled}/>}
    </div>
  );
};

export default SubscriptionScreen;
